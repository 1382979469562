// .hover {
//     border: 8px solid;
//     border-image: #81DB3E;
//     border: 2px solid rgba(255, 255, 255, 0.1);
//     -webkit-mask:
//         conic-gradient(from 180deg at top 8px right 8px, #0000 90deg, #000 0) var(--_i, 200%) 0 /200% var(--_i, 8px) border-box no-repeat,
//         conic-gradient(at bottom 8px left 8px, #0000 90deg, #000 0) 0 var(--_i, 200%)/var(--_i, 8px) 200% border-box no-repeat,
//         linear-gradient(#000 0 0) padding-box no-repeat;
//     transition: .3s, -webkit-mask-position .3s .3s;
// }

// .hover:hover {
//     --_i: 100%;
//     color: #81DB3E;
//     transition: .3s, -webkit-mask-size .3s .3s;
// }


// .ani-border {
//     --c: #8A9B0F;
//     /* the border color */
//     --b: 10px;
//     /* the border thickness*/
//     --g: 5px;
//     /* the gap on hover */

//     padding: calc(var(--g) + var(--b));
//     --_g: #0000 25%, var(--c) 0;
//     background:
//         conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) var(--_i, 200%) 0 /200% var(--_i, var(--b)) no-repeat,
//         conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0 var(--_i, 200%)/var(--_i, var(--b)) 200% no-repeat;
//     transition: .3s, background-position .3s .3s;
//     cursor: pointer;
// }

// .ani-border:hover {
//     --_i: 100%;
//     transition: .3s, background-size .3s .3s;
// }



// border animation start 

@mixin magic-border($width, $color, $duration, $direction) {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width:calc(100% + #{$width * 2});
        height:calc(100% + #{$width * 2});
        top:calc(#{$width}/-1);
        left:calc(#{$width}/-1);
        background: linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to left, $color 0%, $color 100%);
        background-size: 100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
        background-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
        background-repeat: no-repeat, no-repeat;
        transition: transform $duration ease-in-out, background-position $duration ease-in-out, background-size $duration ease-in-out;
        transform: scaleX(0) rotate(180deg * $direction);
        transition-delay: $duration*2, $duration, 0s;
    }

    &:hover {
        &:before {
            background-size: 200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
            background-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
            transform: scaleX(1) rotate(180deg * $direction);
            transition-delay: 0s, $duration, $duration*2;
        }
    }
}

@mixin magic-border-mask($width, $duration, $background, $direction) {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width:calc(100% + #{$width * 2});
        height:calc(100% + #{$width * 2});
        top:calc(#{$width}/-1);
        left:calc(#{$width}/-1);
        mask: linear-gradient(to right, #000 0%, #000 100%), linear-gradient(to top, #000 50%, transparent 50%), linear-gradient(to top, #000 50%, transparent 50%), linear-gradient(to right, #000 0%, #000 100%), linear-gradient(to left, #000 0%, #000 100%);
        mask-size: 100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
        mask-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
        mask-repeat: no-repeat, no-repeat;
        transition: transform $duration ease-in-out, mask-position $duration ease-in-out, mask-size $duration ease-in-out;
        transform: scaleX(0) rotate(180deg * $direction);
        transition-delay: $duration*2, $duration, 0s;
        background: $background;
    }

    &:hover {
        &:before {
            mask-size: 200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
            mask-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
            transform: scaleX(1) rotate(180deg * $direction);
            transition-delay: 0s, $duration, $duration*2;
        }
    }
}




.animate-border {
    text-align: center;
    @include magic-border(2px, #81db4c, 0.2s, 0);

    &:nth-of-type(2) {
        @include magic-border(4px, #222, 0.2s, 1);
    }

}


// border animation end 



